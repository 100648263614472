body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
  font-family: 'Roboto', sans-serif;
}
button:focus{outline: none;}

*:hover{
  transition: 0.3s linear;
}


html {
  scroll-behavior: smooth;
  overflow-y: scroll; /* has to be scroll, not auto */
  outline: none;
  -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

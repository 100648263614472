body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #282828;
}

.App {
  text-align: center;
}

.App-icon {
  height: calc(22px + 2vmin);
  cursor: pointer;
  border-radius: 50%;
}

.App-icon-sm {
  height: calc(16px + 1vmin);
  border-radius: 50%;
}

.Footer-icon {
  height: calc(18px + 1vmin);
  cursor: pointer;
  border-radius: 40%;
  transition: 0.5s ease;
}

.refresh-icon {
  height: calc(22px + 1vmin);
  align-content: flex-start;
  pointer-events: none;
  margin-bottom: 5px;
}

.App-logo {
  margin-top: 35px;
  height: 35vmin;
  pointer-events: none;
  border-radius: 50%;
}

.send {
  border-radius: 25px;
  background-color: #282828;
  width: 20vh;
  height: 6vh;
  text-align: center;
  border: 2px;
  color: white;
  margin-top: 2rem;
}

.Footer-icon:hover {
  opacity: 0.7;
}

.CardCont {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-container {
  text-align: left;
}

.Border-Shadow {
  border-width: 0.15rem;
  border-color: #282c34;
  -webkit-overflow-scrolling: touch;
  -webkit-box-shadow: 5px 6px 0px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 6px 0px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 6px 0px -2px rgba(0, 0, 0, 0.75);
}

.glyphicon {
  margin: 0;
  height: calc(14px + 1vmin);
  cursor: pointer;
}

.glyphicon:hover {
  opacity: 0.7;
}

.cursive {
  font-family: "Tangerine", cursive;
}

.title-caption {
  font-size: calc(14px + 2vmin);
  line-height: 1em;
}
.title {
  font-family: "Dancing Script", cursive;
  font-size: calc(28px + 10vmin);
}

.nav-title {
  font-family: "Tangerine", cursive;
  font-size: calc(12px + 1vmin);
}

.google-maps {
  width: 100%;
}

.Employee {
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 22vh;
  height: 22vh;
  -webkit-overflow-scrolling: touch;
  -webkit-box-shadow: 5px 6px 0px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 6px 0px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 6px 0px -2px rgba(0, 0, 0, 0.75);
  transition: transform 0.5s ease;
  color: white;
}

.Offer {
  width: 32vh;
  height: 26vh;
  border-radius: 8px;
  -webkit-overflow-scrolling: touch;
  -webkit-box-shadow: 5px 6px 0px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 6px 0px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 6px 0px -2px rgba(0, 0, 0, 0.75);
  transition: transform 0.5s ease;
}

.SkinPreview {
  max-width: 50vh;
  max-height: 50vh;
  -webkit-overflow-scrolling: touch;
  transition: transform 0.5s ease;
}

.SkinPreview-sm {
  max-width: 35vh;
  max-height: 50vh;
  -webkit-overflow-scrolling: touch;
  transition: transform 0.5s ease;
}

.Preview {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  -webkit-overflow-scrolling: touch;
  -webkit-box-shadow: 5px 6px 0px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 6px 0px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 6px 0px -2px rgba(0, 0, 0, 0.75);
  transition: transform 0.5s ease;
}

.SkinPreview:hover {
  transform: scale(1.05);
  transition: transform 0.5s ease;
}

.Preview:hover {
  transform: scale(1.05);
  transition: transform 0.5s ease;
}

.Offer:hover {
  transform: scale(1.05);
  transition: transform 0.5s ease;
}

.Employee:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}

.caption {
  display: block;
}

.caption-sm {
  display: block;
  margin-top: 5px;
  font-size: calc(13px + 1vmin);
}

.Skin-Container {
  display: inline-block;
}

.Offer-Container {
  margin: 10px;
  display: inline-block;
}

.Employee-Container {
  margin: 25px;
  display: inline-block;
}

.Shadow-Container {
  display: block;
}

.Shadow {
  border: 1px solid #191919;
  -webkit-box-shadow: 5px 6px 0px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 6px 0px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 8px 6px 0px -2px rgba(0, 0, 0, 0.75);
}

.Show-more {
  margin-top: 25px;
}

.text-big {
  font-size: 3.5rem;
}

.Section {
  display: flex;
  justify-content: center;
}

.Home {
  min-height: 100vh;
  align-items: center;
  padding: 1em;
  background: linear-gradient(to bottom left, #ffffff 0%, #f7f0cf 100%);
  font-size: calc(10px + 2vmin);
  flex-direction: column;
  color: #191919;
}

.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  position: absolute;
  width: 100%;
  z-index: 100;
}

@media (max-width: 991px) {
  .Navbar {
    background-color: #191919;
  }
}

.danger {
  color: crimson;
}

.instagram-widget {
  margin: 2em;
  width: 100%;
  border: 0;
  overflow: hidden;
}

.About {
  min-height: 50vh;
  align-items: center;
  background-color: #191919;
  flex-direction: column;
  padding: calc(25px + 5vmin);
  font-size: calc(5px + 2vmin);
  color: white;
}

.Herr {
  min-height: 50vh;
  background-color: #282828;
  flex-direction: column;
  align-items: center;
  padding: calc(25px + 5vmin);
  font-size: calc(12px + 2vmin);
  color: white;
}

.line {
  border: 0.5px solid #a9a9a9;
  width: 50%;
  border-radius: 20%;
}

.line-dark {
  border: 0.5px solid #282828;
  width: 50%;
  border-radius: 20%;
}

.Lenses {
  min-height: 50vh;
  background: linear-gradient(to bottom left, #e7cb7c 0%, #f7f1cf 69%);
  flex-direction: column;
  align-items: center;
  padding: calc(25px + 5vmin);
  font-size: calc(10px + 1vmin);
  color: #191919;
}

.Dam {
  min-height: 50vh;

  background: #d688c1;
  flex-direction: column;
  align-items: center;
  padding: calc(25px + 5vmin);
  font-size: calc(12px + 2vmin);
  color: #191919;
}

.Hud {
  min-height: 50vh;
  background-color: white;
  flex-direction: column;
  align-items: center;
  padding: calc(25px + 5vmin);
  font-size: calc(10px + 1vmin);
  color: #191919;
}

.underline {
  text-decoration-line: underline;
}

.Fr {
  font-size: calc(6px + 1vmin);
  color: white;
}

.Fr-dark {
  font-size: calc(6px + 1vmin);
  color: black;
}

.Priser {
  min-height: 50vh;
  background-color: rgb(17, 17, 17);
  color: white;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  font-size: calc(12px + 1vmin);
  text-align: left;
}

.Contact {
  background-color: #282828;
  min-height: 50vh;
  flex-direction: column;
  color: white;
  align-items: center;
  display: flex;
}

.container {
  display: block;
  margin: 0;
}

#cont {
  display: none;
  font-size: 18pt;
}

.contact-box {
  border-width: 2px;
  background-color: #191919;
  flex-direction: column;
  align-items: left;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 2rem;
  margin-right: 5px;
  margin-left: 10px;
  justify-content: flex-start;
  padding: calc(20px + 5vmin);
  font-size: calc(10px + 1vmin);
  color: white;
  width: 45vmax;
  -webkit-box-shadow: 10px 11px 0px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 11px 0px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 11px 0px -4px rgba(0, 0, 0, 0.75);
}

.Footer {
  background-color: #191919;
  min-height: 4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 0.5vmin);
  color: white;
}

.Readmore {
  position: absolute;
  bottom: 10px;
  display: flex;
  font-size: 85%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ecf0f1;
}

.brandButton {
  padding: 4px;
  margin: 10px;
  font-weight: bold;
  outline-style: none;
  color: #a9a9a9;
  background-color: transparent;
  border-color: #a9a9a9;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-radius: 2px;
}

.instagram-widget {
  width: 100%;
  border: 0;
  overflow: hidden;
}

.brandButton:hover {
  color: white;
  border-bottom-color: rgb(255, 51, 129);
}

.align-left {
  text-align: start;
}

.footerButton {
  padding: 4px;
  margin: 10px;
  font-size: small;
  outline-style: none;
  color: #a9a9a9;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-radius: 2px;
}

.refreshButton {
  padding: 4px;
  margin: 10px;
  font-size: 16px;
  font-weight: bold;
  outline-style: none;
  color: #a9a9a9;
  background-color: transparent;
  border-color: transparent;
  border-radius: 2px;
}

.margin-left {
  margin-left: 5px;
}

.anchorButton {
  padding: 2px;
  margin: 8px;
  font-size: 16px;
  font-weight: bold;
  outline-style: none;
  color: black;
  background-color: transparent;
  border-color: transparent;
  border-radius: 2px;
  transition: 0.5s ease;
}

.Media-footers {
  margin: 1rem;
}

.anchorButton:hover {
  color: black;
}

.scrollToTop {
  display: inline-block;
  min-height: 5vh;
  min-width: 5vh;
  position: fixed;
  right: 10px;
  bottom: 50px;
  z-index: 1000;
  border-radius: 5px;
  border: 1.5px solid #282828;
  background-color: #191919;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease;
}

.arrow-up {
  width: 0;
  height: 0;
  border-radius: 1px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;

  border-bottom: 9px solid white;
}

.scrollToTop:hover {
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

.ToTop {
  height: calc(40px + 2vmin);
  transform: rotate(180deg);
  cursor: pointer;
}

.contToTop {
  display: none;
  cursor: pointer;
}

.Readmore:hover {
  color: #ffffff;
  cursor: pointer;
}

.header {
  letter-spacing: calc(2px + 0.5vmin);
  font-size: xx-large;
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes typing {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  from {
    border-right-color: #ff5733;
  }
  to {
    border-right-color: transparent;
  }
}
